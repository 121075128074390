*{
    box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}


.box {
    min-height: 100vh;
    position: relative;
}
#home,
#portifolio {
    background-color: #101010;
    color: #fff;
}

footer {
    color: white;
    min-height: 150px;
    background-color: black;
}

footer div:first-child {
    background-color: #0a0a0a;
}
footer div:last-child {
    height: 50px;
}
footer a i {
    color: #fff;
}
